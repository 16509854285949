import logo from '../logo.svg';
import discord from '../img/discord.svg';
import twitter from '../img/twitter.svg';

export default function Footer({ setPage }) {
  return (
    <div style={styles.container}>
      <div style={styles.footerLeft}>
        <span>
          <img src={logo} alt="Atlantis Gate" />
        </span>
        <span>
          <p style={styles.logoText}>ATLANTIS GATE</p>
        </span>
      </div>

      <div style={styles.footerMiddle}>
        <div className="navbar">
          <p style={{ cursor: 'pointer' }} onClick={() => setPage('home')}>
            Home
          </p>
          <p style={{ cursor: 'pointer' }} onClick={() => setPage('fair')}>
            Fairness
          </p>
          <p
            style={{ cursor: 'pointer' }}
            onClick={() => setPage('leaderboards')}
          >
            Leaderboards
          </p>
          <p style={{ cursor: 'pointer' }} onClick={() => setPage('about')}>
            About
          </p>
        </div>
        <div className="navbar">
          <p
            style={{ cursor: 'pointer' }}
            onClick={() => setPage('transactions')}
          >
            Transactions
          </p>
          <p style={{ cursor: 'pointer' }} onClick={null}>
            Privacy Policy
          </p>
          <p style={{ cursor: 'pointer' }} onClick={null}>
            Terms & Conditions
          </p>
        </div>
      </div>

      <div style={styles.footerRight}>
        <img
          onClick={() => window.open('https://discord.gg/YbTdkKnewj', '_blank')}
          alt="discord"
          style={styles.sociallogos}
          src={discord}
        />
        <img
          onClick={() => window.open('https://twitter.com/Avasharks', '_blank')}
          alt="twitter"
          style={styles.sociallogos}
          src={twitter}
        />
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    backgroundColor: '#091233',
    color: 'white',
    alignItems: 'center',
    marginTop: '15px',
    padding: '15px',
  },
  logoText: {
    fontSize: '20px',
    fontWeight: 'bolder',
    marginRight: '70px',
  },
  footerLeft: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  footerMiddle: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  footerRight: {
    display: 'flex',
    flex: 1,
    justifyContent: 'right',
  },
  sociallogos: {
    width: '30px',
    marginRight: '10px',
    cursor: 'pointer',
  },
};
