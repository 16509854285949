import * as React from 'react';
import Button from '@mui/material/Button';
import { ref, onValue } from 'firebase/database';
import { db } from '../hooks/useFirebase.js';
import 'dotenv/config';

export default function AdminPanel() {
  const [gamesPlayed, setGamesPlayed] = React.useState(null);
  const [totalBids, setTotalBids] = React.useState(null);
  const [headsTotal, setHeadsTotal] = React.useState(null);
  const [tailsTotal, setTailsTotal] = React.useState(null);
  const [totalPlayers, setTotalPlayers] = React.useState(null);
  const [closePanel, setClosePanel] = React.useState(true);

  //Game Stats
  React.useEffect(() => {
    let total_bids = 0;
    let total_games_played = [];
    let total_heads = [];
    let total_tails = [];
    const allGames = ref(db, 'games/');
    onValue(allGames, (game_data) => {
      game_data.forEach((game_stats) => {
        let game = game_stats.val();
        total_bids += game.hostBid;
        total_games_played.push(game);
        if (game.result === 0) total_heads.push(game);
        if (game.result === 1) total_tails.push(game);
      });
      setHeadsTotal(total_heads.length);
      setTailsTotal(total_tails.length);
      setTotalBids(total_bids);
      setGamesPlayed(total_games_played.length);
    });
  }, []);
  //Get all players
  React.useEffect(() => {
    let total_players = [];
    const all_players = ref(db, 'players/');
    onValue(all_players, (player_data) => {
      let players = player_data;
      players.forEach((player_data) => {
        let player = player_data.val();
        total_players.push(player);
      });
      setTotalPlayers(total_players.length);
    });
  }, []);

  return (
    <div style={styles.container}>
      {!closePanel ? (
        <>
          <div style={styles.box}>
            <div style={styles.titleContainer}>
              <h3>Bank Stats</h3>
            </div>
            <div style={styles.titleText}>
              <p>Balance: Unknown</p>
              <p>Total Bids: {totalBids}</p>
            </div>
          </div>
          <div style={styles.box}>
            <div style={styles.titleContainer}>
              <h3>Game Stats</h3>
            </div>
            <div style={styles.titleText}>
              <p>Total Games: {gamesPlayed}</p>
              <p>Heads: {headsTotal}</p>
              <p>Tails: {tailsTotal}</p>
            </div>
          </div>
          <div style={styles.box}>
            <div style={styles.titleContainer}>
              <h3>Player Stats</h3>
            </div>
            <div style={styles.titleText}>
              <p>Total Players: {totalPlayers}</p>
              <p>Players Online: 0</p>
            </div>
          </div>
          <div style={styles.box}>
            <div style={styles.titleContainer}>
              <h3>Controls</h3>
            </div>
            <div style={styles.titleText}>
              <div style={styles.switchContainer}>
                <p style={styles.switchText}>Game Live</p>
                <div style={styles.switchOn}>On</div>
                <div style={styles.switchOff}>Off</div>
              </div>
            </div>
          </div>
          <h3 onClick={() => setClosePanel(true)} style={styles.exitButton}>
            X
          </h3>
        </>
      ) : (
        <Button onClick={() => setClosePanel(false)} style={styles.exitButton}>
          Open Admin
        </Button>
      )}
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    padding: 20,
    flexWrap: 'wrap',
  },
  box: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#123570',
    borderRadius: 5,
    margin: 10,
    minWidth: 250,
  },
  titleContainer: {
    backgroundColor: 'black',
    padding: 10,
  },
  titleText: {
    padding: 10,
  },
  switchContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  switchOn: {
    display: 'flex',
    flex: 1,
    padding: 3,
    fontWeight: 'bold',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'grey',
    marginRight: 10,
    cursor: 'pointer',
  },
  switchOff: {
    display: 'flex',
    flex: 1,
    padding: 3,
    justifyContent: 'center',
    fontWeight: 'bold',
    alignItems: 'center',
    backgroundColor: 'red',
    cursor: 'pointer',
  },
  switchText: {
    marginRight: 10,
  },
  exitButton: {
    cursor: 'pointer',
  },
};
