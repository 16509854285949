import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MoralisProvider } from 'react-moralis';

const { REACT_APP_SERVER_URL, REACT_APP_APP_ID } = process.env;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MoralisProvider serverUrl={REACT_APP_SERVER_URL} appId={REACT_APP_APP_ID}>
    <App />
  </MoralisProvider>
);
reportWebVitals();
