import { useCallback, useEffect, useState } from 'react';
import { Alert, Snackbar, Avatar, Stack, Badge } from '@mui/material';
import { useMoralis, useMoralisCloudFunction } from 'react-moralis';
import { ref, onValue } from 'firebase/database';
import { backgroundEFX } from '../utils/animations.js';
import Coin from '../components/Coin/index.jsx';
import { db } from '../hooks/useFirebase.js';
import { HEADS } from '../config/constants.js';
import tailsMini from '../img/tails-mini.svg';
import headsMini from '../img/heads-mini.svg';
import avax from '../img/avax.svg';
import vs from '../img/vs.svg';
import Web3 from 'web3';

export default function GameCard({ game }) {
  const { user, Moralis } = useMoralis();
  const [error, setError] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [currentPlayer, setCurrentPlayer] = useState();
  const [hostPlayer, setHostPlayer] = useState();
  const [oppPlayer, setOppPlayer] = useState();
  const [joinButton, setJoinButton] = useState(true);
  const [showWinner, setShowWinner] = useState(false);
  const [loading, setLoading] = useState(true);
  const [canceling, setCanceling] = useState(false);
  const [winner, setWinner] = useState('');
  const [winnings, setWinnings] = useState(0);

  const hostBid = +Moralis.Units.FromWei(`${game.hostBid || 0}`);

  const params = { gameId: game.gid };
  const { fetch: cancelGame } = useMoralisCloudFunction('cancelGame', params, {
    autoFetch: false,
  });

  const { fetch: joinGame } = useMoralisCloudFunction('joinGame', params, {
    autoFetch: false,
  });

  const { fetch: syncPlayer } = useMoralisCloudFunction(
    'syncPlayer',
    {},
    { autoFetch: false }
  );

  const processCancelGame = useCallback(async () => {
    if (!canceling) {
      setCanceling(true);
      await cancelGame();
    }
  }, [canceling, cancelGame]);

  useEffect(() => {
    let disconnectGameListener = () => {};
    let disconnectHostListener = () => {};
    let disconnectPlayerListener = () => {};

    const nextGame = () => {
      document.getElementById(`${game.gid}-vs`).style.display = 'flex';
      document.getElementById(`${game.gid}-coin-container`).style.display =
        'none';
      document.getElementById(`${game.gid}-container`).animate(
        [
          // keyframes
          {
            transform: 'scale(0)',
            backgroundColor: '#091233',
          },
          {
            transform: 'scale(1)',
            backgroundColor: '#091233',
          },
        ],
        {
          // timing options
          duration: 1000,
          iterations: 1,
          transition: 'easeInOut',
          fill: 'forwards',
        }
      );
    };

    if (game && user) {
      const gamePath = ref(db, 'games/' + game.gid);
      disconnectGameListener = onValue(gamePath, (data) => {
        let game = data.val();
        if (game.processing && game.opponent) {
          document.getElementById(`${game.gid}-vs`).style.display = 'none';
          document.getElementById(`${game.gid}-coin-container`).style.display =
            'flex';
          backgroundEFX(game, user);
        }
        if (game.live === false) {
          nextGame(game.gid);
        }
      });

      const hostPath = ref(db, 'players/' + game.host);
      disconnectHostListener = onValue(hostPath, (data) => {
        let player = data.val();
        setHostPlayer(player);
      });

      const playerPath = ref(db, 'players/' + user.id);
      disconnectPlayerListener = onValue(playerPath, (data) => {
        let player = data.val();
        setCurrentPlayer(player);
        setLoading(false);
      });
    }

    return () => {
      disconnectGameListener();
      disconnectHostListener();
      disconnectPlayerListener();
    };
  }, [game, user]);

  useEffect(() => {
    let disconnectOppListener = () => {};
    if (game.opponent) {
      const playerPath = ref(db, 'players/' + game.opponent);
      disconnectOppListener = onValue(playerPath, (data) => {
        let player = data.val();
        setOppPlayer(player);
      });
    }
    return () => {
      disconnectOppListener();
    };
  }, [game.opponent]);

  useEffect(() => {
    if (!isNaN(game.result)) {
      const bn = Web3.utils.BN;
      const gameBid = new bn(`${game.hostBid}`);
      const total_pot = gameBid.mul(new bn(2));
      const percent = total_pot.div(new bn(100)).mul(new bn(3));
      const pot = total_pot.sub(percent);
      if (game.hostChoice === game.result) {
        setWinner('HOST');
      }
      if (game.hostChoice !== game.result) {
        setWinner('NOT_HOST');
      }
      setWinnings(+Moralis.Units.FromWei(pot.toString()));
      setTimeout(() => {
        setShowWinner(true);
      }, 3000);
      setTimeout(() => {
        syncPlayer();
      }, 6000);
      setTimeout(() => {
        syncPlayer();
      }, 9000);
    }
  }, [Moralis, syncPlayer, game.result, game.hostBid, game.hostChoice]);

  const joinGameHandler = async () => {
    if (currentPlayer.balance < game.hostBid) {
      setError('You do not have enough tokens');
      setAlertOpen(true);
      return;
    }
    setJoinButton(false);
    await joinGame();
  };

  function handleClose() {
    setAlertOpen(false);
  }

  return (
    <>
      <div
        id={`${game.gid}-container`}
        style={
          game.host === user.id || game.opponent === user.id
            ? styles.containerLive
            : styles.container
        }
      >
        <div style={styles.cardLeft}>
          <Stack direction="row" spacing={2}>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              badgeContent={
                <Avatar
                  style={{
                    width: '35px',
                    height: '35px',
                    marginBottom: '10px',
                  }}
                  alt="Coin image"
                  src={game.hostChoice === HEADS ? headsMini : tailsMini}
                />
              }
            >
              <Avatar
                style={{ width: '65px', height: '65px', marginBottom: '10px' }}
                alt="Coin image"
                src={!loading ? hostPlayer?.avatar : 'Loading..'}
              />
            </Badge>
          </Stack>
          <div>
            {!loading && hostPlayer?.name
              ? hostPlayer?.name
              : game.host.slice(0, 10)}
          </div>
          {(showWinner && winner === 'HOST' && (
            <button
              disabled={!(game.host === user.id)}
              className="claim-button"
            >
              <img
                src={avax}
                style={{
                  display: 'flex',
                  width: '17px',
                  height: '17px',
                  marginRight: '5px',
                }}
                alt="Avax Logo"
              />
              +{winnings.toFixed(3)}
            </button>
          )) ||
            (showWinner && winner === 'NOT_HOST' && (
              <button
                disabled={game.host === user.id}
                className="looser-button"
              >
                <img
                  src={avax}
                  style={{
                    display: 'flex',
                    width: '17px',
                    height: '17px',
                    marginRight: '5px',
                  }}
                  alt="Avax Logo"
                />
                -{hostBid.toFixed(3)}
              </button>
            )) || (
              <div style={styles.betBtn}>
                <img
                  src={avax}
                  style={{
                    display: 'flex',
                    width: '17px',
                    height: '17px',
                    marginRight: '5px',
                  }}
                  alt="Avax Logo"
                />
                <p>{hostBid.toFixed(3)}</p>
              </div>
            )}
        </div>
        <div style={styles.cardMiddle}>
          <img id={`${game.gid}-vs`} src={vs} alt="vs" />
          <div id={`${game.gid}-coin-container`} style={styles.coinContainer}>
            <Coin flip={game.result === HEADS ? 'heads' : 'tails'} />
          </div>
        </div>
        <div style={styles.cardRight}>
          <Stack direction="row" spacing={2}>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              badgeContent={
                <Avatar
                  style={{
                    width: '35px',
                    height: '35px',
                    marginBottom: '10px',
                  }}
                  alt="Coin image"
                  src={game.hostChoice === HEADS ? tailsMini : headsMini}
                />
              }
            >
              <Avatar
                style={{ width: '65px', height: '65px', marginBottom: '10px' }}
                alt="Coin image"
                src={game.opponent ? oppPlayer?.avatar : null}
              />
            </Badge>
          </Stack>
          <div>{game.opponent ? oppPlayer?.name : 'Waiting...'}</div>
          {game.host === user.id && !showWinner && !game.opponent ? (
            <button
              disabled={canceling}
              onClick={processCancelGame}
              className="cancel-btn"
            >
              {!canceling ? (
                <span>Cancel</span>
              ) : (
                <div class="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}
            </button>
          ) : joinButton && !showWinner && !game.opponent ? (
            <button onClick={joinGameHandler} className="join-btn">
              Join Game
            </button>
          ) : !joinButton && !showWinner ? (
            <button id="next-game-count" onClick={null} className="stats-btn">
              Good Luck!
            </button>
          ) : (
            (showWinner && winner === 'NOT_HOST' && (
              <>
                <span>Winner</span>
                <button
                  disabled={game.host === user.id}
                  className="claim-button"
                >
                  <img
                    src={avax}
                    style={{
                      display: 'flex',
                      width: '17px',
                      height: '17px',
                      marginRight: '5px',
                    }}
                    alt="Avax Logo"
                  />
                  +{winnings.toFixed(3)}
                </button>
              </>
            )) ||
            (showWinner && winner === 'HOST' && (
              <>
                <span>Loser</span>
                <button
                  disabled={game.host === user.id}
                  className="looser-button"
                >
                  <img
                    src={avax}
                    style={{
                      display: 'flex',
                      width: '17px',
                      height: '17px',
                      marginRight: '5px',
                    }}
                    alt="Avax Logo"
                  />
                  -{hostBid.toFixed(3)}
                </button>
              </>
            ))
          )}
        </div>
      </div>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
}

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    maxWidth: '425px',
    minWidth: '425px',
    maxHeight: '190px',
    height: '190',
    padding: '20px',
    paddingTop: 35,
    border: `3px solid #283c86`,
    borderRadius: '5px',
    backgroundColor: '#091233',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url(https://tmir.mypinata.cloud/ipfs/QmQSKx32KyZSNULGg1BjnTet4t8iUHujw9qhWKd69BWDRu/cardBg.svg)`,
  },
  containerLive: {
    display: 'flex',
    flex: 1,
    maxWidth: '425px',
    minWidth: '425px',
    maxHeight: '190px',
    height: '190',
    padding: '20px',
    paddingTop: 35,
    border: `3px solid yellow`,
    borderRadius: '5px',
    backgroundColor: '#091233',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url(https://tmir.mypinata.cloud/ipfs/QmQSKx32KyZSNULGg1BjnTet4t8iUHujw9qhWKd69BWDRu/cardBg.svg)`,
  },
  cardLeft: {
    display: 'flex',
    flex: 1,
    color: 'white',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardMiddle: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
  cardRight: {
    display: 'flex',
    flex: 1,
    color: 'white',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  coinContainer: {
    display: 'none',
    position: 'relative',
    width: '126px',
    height: '126px',
  },
  betBtn: {
    display: 'flex',
    fontSize: '14px',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#101838',
    padding: '7px 10px',
    marginTop: '5px',
    borderRadius: '5px',
  },
};
