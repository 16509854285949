import { useNativeBalance } from 'react-moralis';
import { useEffect, useState } from 'react';
import { ref, onValue } from 'firebase/database';
import { db } from '../hooks/useFirebase.js';
import { useMoralis } from 'react-moralis';

export default function GetBalance() {
  const { data: balance } = useNativeBalance();
  const [loading, isLoading] = useState(true);
  const [gameBalance, setGameBalance] = useState(0);
  const { user } = useMoralis();

  //Get players
  useEffect(() => {
    isLoading(true);
    if (user) {
      const playerPath = ref(db, 'players/' + user.id);
      onValue(playerPath, (data) => {
        let player = data.val();
        setGameBalance(player?.coins ?? 0);
        isLoading(false);
      });
    }
  }, [user]);

  return (
    <div style={styles.container}>
      <div style={styles.balanceContainer}>
        <p style={styles.balanceTitle}>Game</p>
        <p style={{ marginLeft: 10 }}>
          {!loading ? gameBalance?.toFixed(2) : 'Loading..'}
        </p>
      </div>
      <div style={styles.balanceContainer}>
        <p style={styles.balanceTitle1}>Wallet</p>
        <p style={{ marginLeft: 10 }}>{balance.formatted}</p>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flex: 1,
  },
  balanceContainer: {
    display: 'flex',
    backgroundColor: 'black',
    padding: 10,
    margin: 10,
  },
  balanceTitle: {
    fontSize: 16,
    color: 'yellow',
    marginBottom: 5,
  },
  balanceTitle1: {
    fontSize: 16,
    color: 'green',
  },
};
