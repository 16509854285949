import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useMoralis, useMoralisCloudFunction } from 'react-moralis';
import CircularProgress from '@mui/material/CircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { address, abi } from '../utils/contract';

const options = {
  // @testnet
  // chain: 'avalanche testnet',
  chain: 'avalanche',
};

export default function DepositWithdraw({ bal, currentBal }) {
  const { Moralis } = useMoralis();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setTxHash(undefined);
  };
  const [transferAmount, setTransferAmount] = React.useState(0);
  const [isTransfering, setIsTransfering] = React.useState(false);
  const [txHash, setTxHash] = React.useState(undefined);
  const [balance, setBalance] = React.useState(undefined);

  const { fetch: syncPlayer } = useMoralisCloudFunction(
    'syncPlayer',
    {},
    { autoFetch: false }
  );

  const params = { amount: +transferAmount };
  const { fetch: withdraw } = useMoralisCloudFunction('withdraw', params, {
    autoFetch: false,
  });

  const updateBalance = React.useCallback(() => {
    Moralis.Web3API.account.getNativeBalance(options).then((b) => {
      setBalance((+Moralis.Units.FromWei(b.balance)).toFixed(4));
    });
  }, [Moralis]);

  React.useEffect(() => {
    if (open) {
      updateBalance();
    }
  }, [open, updateBalance]);

  function handleTransfer(e) {
    setTransferAmount(e.target.value);
  }

  const DepositEth = () => {
    const [isFetching, setIsFetching] = React.useState(false);
    const sendOptions = {
      contractAddress: address,
      functionName: 'deposit',
      abi: abi,
      msgValue: Moralis.Units.ETH(+transferAmount || 0),
    };
    return (
      <div>
        <Button
          fullWidth
          onClick={async () => {
            setIsFetching(true);
            setIsTransfering(true);
            const transaction = await Moralis.executeFunction(sendOptions);
            const result = await transaction.wait();
            setTxHash(result.transactionHash);
            setIsTransfering(false);
            setIsFetching(false);

            syncPlayer({
              onSuccess: (data) => {},
            });

            setTimeout(() => updateBalance(), 3000);
            setTimeout(() => updateBalance(), 6000);
          }}
          disabled={isFetching}
          color="success"
          variant="contained"
          style={styles.depositBtn}
        >
          Deposit
        </Button>
      </div>
    );
  };

  async function Withdraw() {
    if (transferAmount > currentBal) return alert('Not enough Avax');
    setIsTransfering(true);
    withdraw({
      onSuccess: (data) => {
        setIsTransfering(false);
        syncPlayer({
          onSuccess: (data) => {},
        });
        setTimeout(() => updateBalance(), 3000);
        setTimeout(() => updateBalance(), 6000);
      },
    });
  }

  return (
    <div>
      <Button onClick={handleOpen}>{bal}</Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={styles.modal}>
          <div style={{ display: 'flex', marginBottom: 7 }}>
            Game Balance:&nbsp;{bal}
          </div>
          <div style={{ display: 'flex', marginBottom: 7 }}>
            Wallet Balance:&nbsp;<div>{`${balance} AVAX`}</div>
          </div>
          {isTransfering ? <CircularProgress /> : null}
          <a
            href={txHash ? `https://snowtrace.io/tx/${txHash}` : null}
            target="_blank"
            rel="noreferrer"
            style={styles.hashtext}
          >
            {txHash ? (
              <p style={{ display: 'flex', marginBottom: 15 }}>
                <span style={{ color: 'white', marginRight: 10 }}>
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    style={{ color: 'green', marginRight: 5 }}
                  />
                  Transaction Hash:
                </span>{' '}
                {txHash.slice(0, 15)}
              </p>
            ) : null}
          </a>
          <input
            style={styles.input}
            placeholder="0"
            id="transferAmount"
            type="number"
            min="0"
            max={currentBal}
            onChange={handleTransfer}
          />
          <DepositEth />
          <Button
            fullWidth
            style={{ marginTop: 10 }}
            onClick={Withdraw}
            variant="outlined"
          >
            Withdraw
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

const styles = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    color: 'white',
    bgcolor: 'white',
    border: '2px solid #283c86',
    borderRadius: '5px',
    backgroundColor: '#101838',
    boxShadow: 24,
    p: 4,
  },
  input: {
    display: 'flex',
    flex: 1,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 10px',
    fontSize: '25px',
    backgroundColor: '#101830',
    color: 'white',
    border: '1px solid #283c86',
    borderRadius: '5px',
  },
  depositBtn: {
    marginTop: 10,
  },
};
