import fairbg from '../img/fair-bg.png';

export default function About() {
  return (
    <div style={styles.container}>
      <div style={styles.left}>
        <h2 style={styles.fairh2}>About Atlantis Gate</h2>
        <br></br>
        <p style={{ lineHeight: '1.75rem', fontSize: '18px' }}>
          Atlantis Gate is a wagering platform powered by AvaSharks. Our humble
          beginnings are now a pvp coin flip game where holders of the AvaShark
          NFT's get a share from the revenues of the the platform.
        </p>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    padding: '50px 150px',
    backgroundImage: `url(${fairbg})`,
    height: '800px',
  },
  left: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '25px',
    color: 'white',
    fontSize: '16px',
  },
  right: {
    display: 'flex',
    flex: 1,
    padding: '25px',
    color: 'white',
    fontSize: '16px',
  },
  fairh2: {
    fontSize: '40px',
  },
};
