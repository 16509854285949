import { useEffect, useState } from 'react';
import { useMoralis, useMoralisQuery } from 'react-moralis';
import { getWalletFromId } from '../../utils/addressId';

const streak = (arr, streakValue) => {
  var i,
    temp,
    streak = 1,
    length = arr.length,
    highestStreak = -100;

  for (i = 0; i < length; i++) {
    // check the value of the current entry against the last
    if (temp !== '' && temp === arr[i] && temp === streakValue) {
      // it's a match
      streak++;
    } else {
      // it's not a match, start streak from 1
      streak = 1;
    }

    // set current letter for next time
    temp = arr[i];

    // set the master streak var
    if (streak > highestStreak) {
      highestStreak = streak;
    }
  }

  return highestStreak;
};

const useStats = () => {
  const { Moralis } = useMoralis();
  const [stats, setStats] = useState({ table: [], loading: true });

  const { fetch } = useMoralisQuery(
    'EventsWalletMoveBalance',
    (query) => query.limit(10000),
    [],
    { autoFetch: false }
  );

  useEffect(() => {
    const playerQuery = new Moralis.Query('Player');
    // const playerQuery = new Moralis.Query('Player');

    fetch().then(async (results) => {
      console.log('Successfully retrieved ' + results.length + ' games.');

      const volume = results.reduce((acc, item) => {
        return acc + +item.get('amount');
      }, 0);

      const uniqueWallets = results.reduce((acc, item) => {
        if (!acc.includes(item.get('from'))) {
          acc.push(item.get('from'));
        }
        if (!acc.includes(item.get('to'))) {
          acc.push(item.get('to'));
        }
        return acc;
      }, []);

      let highestBidWon = {
        amount: 0,
        timestamp: 0,
        winner: '',
      };

      let gamesWon = {};
      let gamesLost = {};
      let gamesPlayed = {};
      let gamesWinnings = {};

      let gamesResults = results;
      // let gamesResults = results.filter(
      //   (game) =>
      //     game.get('block_timestamp').getTime() <
      //     new Date(2022, 7, 6, 1, 0, 0).getTime()
      // );

      const winners = gamesResults.map((game) => game.get('to'));
      const losers = gamesResults.map((game) => game.get('from'));
      const players = new Set([...winners, ...losers]);
      const playersArray = Array.from(players);

      const winStreaks = {};
      const loseStreaks = {};

      playersArray.forEach((player) => {
        const gs = gamesResults
          .filter(
            (game) => game.get('to') === player || game.get('from') === player
          )
          .map((game) => (game.get('to') === player ? 1 : 0));

        winStreaks[player] = streak(gs, 1);
        loseStreaks[player] = streak(gs, 0);
      });

      for (let i = 0; i < gamesResults.length; i++) {
        const game = gamesResults[i];

        if (+game.get('amount') > highestBidWon.amount) {
          highestBidWon = {
            amount: 2 * +game.get('amount') * 0.97 - +game.get('amount'),
            winner: game.get('to'),
          };
        }

        if (!gamesPlayed[game.get('from')]) {
          gamesPlayed[game.get('from')] = 0;
        }

        if (!gamesPlayed[game.get('to')]) {
          gamesPlayed[game.get('to')] = 0;
        }

        if (!gamesWon[game.get('to')]) {
          gamesWon[game.get('to')] = 0;
        }

        if (!gamesLost[game.get('from')]) {
          gamesLost[game.get('from')] = 0;
        }

        if (!gamesWinnings[game.get('to')]) {
          gamesWinnings[game.get('to')] = 0;
        }

        gamesPlayed[game.get('from')] += 1;
        gamesPlayed[game.get('to')] += 1;

        gamesWon[game.get('to')] += 1;
        gamesLost[game.get('from')] += 1;

        gamesWinnings[game.get('to')] +=
          2 * +game.get('amount') * 0.97 - +game.get('amount');
      }

      const highestBidWinnerAddress = highestBidWon.winner;

      const gamesWonAddress = Object.entries(gamesWon)
        .map(([key, value]) => ({
          address: key,
          gamesWon: value,
        }))
        .sort((a, b) => b.gamesWon - a.gamesWon)[0];

      const gamesPlayedAddress = Object.entries(gamesPlayed)
        .map(([key, value]) => ({
          address: key,
          gamesPlayed: value,
        }))
        .sort((a, b) => b.gamesPlayed - a.gamesPlayed)[0];

      const gamesWinningsAddress = Object.entries(gamesWinnings)
        .map(([key, value]) => ({
          address: key,
          gamesWinnings: value,
        }))
        .sort((a, b) => b.gamesWinnings - a.gamesWinnings)
        .map((v) => ({
          ...v,
          gamesWinnings: Moralis.Units.FromWei(`${v.gamesWinnings}`) + ' AVAX',
        }))[0];

      const gamesLostAddress = Object.entries(gamesLost)
        .map(([key, value]) => ({
          address: key,
          gamesLost: value,
        }))
        .sort((a, b) => b.gamesLost - a.gamesLost)[0];

      const gamesWinStreakAddress = Object.entries(winStreaks)
        .map(([key, value]) => ({
          address: key,
          wininarow: value,
        }))
        .sort((a, b) => b.wininarow - a.wininarow)[0];

      const gamesWinStreakWinningsAddress = Object.entries(winStreaks)
        .map(([key, value]) => ({
          address: key,
          wininarow: value,
        }))
        .sort((a, b) => b.wininarow - a.wininarow)
        .filter((v) => v.wininarow > 3)
        .map((v) => ({
          address: v.address,
          wins: gamesWinnings[v.address],
        }))
        .sort((a, b) => b.wins - a.wins)[0];

      const gamesLostStreakAddress = Object.entries(loseStreaks)
        .map(([key, value]) => ({
          address: key,
          lostinarow: value,
        }))
        .sort((a, b) => b.lostinarow - a.lostinarow)[0];

      const tableStats = Object.entries(gamesPlayed)
        .map(([key, value]) => ({
          address: key,
          gamesPlayed: value,
        }))
        .map((game) => ({
          ...game,
          gamesWon: gamesWon[game.address] || 0,
          gamesLost: gamesLost[game.address] || 0,
          gamesWinnings: Moralis.Units.FromWei(
            `${gamesWinnings[game.address] || 0}`
          ),
        }))
        .sort((a, b) => b.gamesPlayed - a.gamesPlayed);

      setStats({
        table: tableStats,
        moreData: {
          gamesWonAddress:
            gamesWonAddress.address + ` (${gamesWonAddress.gamesWon} games)`,
          gamesLostAddress:
            gamesLostAddress.address + ` (${gamesLostAddress.gamesLost} games)`,
          gamesPlayedAddress:
            gamesPlayedAddress.address +
            ` (${gamesPlayedAddress.gamesPlayed} games)`,
          gamesWinningsAddress:
            gamesWinningsAddress.address +
            ` (${gamesWinningsAddress.gamesWinnings})`,
          highestBidWinnerAddress:
            highestBidWinnerAddress +
            ` (${Moralis.Units.FromWei(`${highestBidWon.amount}`)} AVAX)`,
          winnerInRowAddress:
            gamesWinStreakAddress.address +
            ` (${gamesWinStreakAddress.wininarow} games)`,
          loserInRowAddress:
            gamesLostStreakAddress.address +
            ` (${gamesLostStreakAddress.lostinarow} games)`,
          specialInARowWinningsOver3Games:
            gamesWinStreakWinningsAddress.address +
            ` (${Moralis.Units.FromWei(
              `${gamesWinStreakWinningsAddress.wins}`
            )} AVAX)`,
          volume: Moralis.Units.FromWei(`${volume}`) + ' AVAX',
          wallets: uniqueWallets.length,
          games: gamesResults.length,
        },
        loading: false,
      });
    });
  }, [fetch, Moralis]);

  return stats;
};

export default useStats;
