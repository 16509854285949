import { Box, Button, Grid, Modal, Stack } from '@mui/material';
import styles from './styles.module.css';
import AddBoxIcon from '@mui/icons-material/AddBox';
import SaveFile from '../../modules/saveFile';
import React from 'react';
import { useMoralis } from 'react-moralis';
import useTransactions from '../../hooks/useTransactions';

const ProfileModal = ({
  open,
  handleClose,
  user,
  walletAddress,
  logout,
  loading,
  profileImage,
  openImageSelect,
  setPage,
}) => {
  const { Moralis } = useMoralis();
  const { stats } = useTransactions(walletAddress);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.box}>
        <Grid container spacing={6} direction="row">
          <Grid item xs={3}>
            <Stack
              spacing={4}
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              <div className={styles.text}>Profile Picture</div>
              <div className={styles.image}>
                {(!loading && profileImage && (
                  <img
                    src={!loading ? profileImage : 'Loading..'}
                    alt="Players"
                    onClick={openImageSelect}
                    className={styles.profileImage}
                  />
                )) || <AddBoxIcon fontSize="large" />}
              </div>
              <SaveFile />
            </Stack>
          </Grid>
          <Grid item xs={8}>
            <div className={styles.rightContainer}>
              <div className={styles.vDivider} />
              <div className={styles.rightBox}>
                <div className={styles.topRight}>
                  {' '}
                  <div className={styles.ID}>
                    <span className={styles.before}>ID: </span>{' '}
                    <span className={styles.walletAddress}>{user.id}</span>
                  </div>
                  <div className={styles.wallet}>
                    <span className={styles.before}>Wallet Address:</span>
                    <span className={styles.walletAddress}>
                      {' '}
                      {walletAddress}
                    </span>
                  </div>
                  <div className={styles.transactionsAnchor}>
                    <a
                      onClick={() => {
                        setPage('transactions');
                        handleClose();
                      }}
                    >
                      My Transaction History
                    </a>
                  </div>
                </div>
                {
                  <div className={styles.bottomRight}>
                    <div className={styles.Stats}>
                      <div className={styles.Stat}>
                        <span className={styles.before}>Games Played:</span>{' '}
                        {stats?.games || 0}
                      </div>
                      <div className={styles.Stat}>
                        <span className={styles.before}>Games Won:</span>{' '}
                        {stats?.gamesWon || 0}
                      </div>
                      <div className={styles.Stat}>
                        <span className={styles.before}>Games Lost:</span>{' '}
                        {stats?.gamesLost || 0}
                      </div>
                    </div>
                    <div className={styles.Stats}>
                      <div className={styles.Stat}>
                        <span className={styles.before}>Won Bids:</span>{' '}
                        <span style={{ color: 'green' }}>
                          {(+Moralis.Units.FromWei(
                            `${stats.gamesWonEarnings || 0}`
                          )).toFixed(3)}{' '}
                          AVAX
                        </span>
                      </div>
                      <div className={styles.Stat}>
                        <span className={styles.before}>Lost Bids:</span>{' '}
                        <span style={{ color: 'red' }}>
                          {(+Moralis.Units.FromWei(
                            `${stats.gamesLostEarnings || 0}`
                          )).toFixed(3)}{' '}
                          AVAX
                        </span>
                      </div>
                      <div className={styles.Stat}>
                        <span className={styles.before}>Total Profit:</span>{' '}
                        <span
                          style={{ color: stats.profit < 0 ? 'red' : 'green' }}
                        >
                          {(+Moralis.Units.FromWei(
                            `${stats.profit || 0}`
                          )).toFixed(3)}{' '}
                          AVAX
                        </span>
                      </div>
                    </div>
                  </div>
                }
                <div className={styles.buttonDiv}>
                  <Button
                    className={styles.logout}
                    onClick={logout}
                    variant="outlined"
                    color="error"
                  >
                    Disconnect
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ProfileModal;
