import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Box,
} from '@mui/material';
import { useMoralis } from 'react-moralis';
import useTransactions from '../../hooks/useTransactions';
import styles from './styles.module.css';

const Transactions = ({ walletAddress }: Props) => {
  const { Moralis } = useMoralis();
  const { stats, table } = useTransactions((walletAddress || '').toLowerCase());

  return (
    <>
      {stats.games !== undefined && (
        <Box sx={{ maxWidth: 850, margin: '50px auto' }}>
          <span>Games Played: {stats.games}</span>
          <br />
          <span style={{ color: 'green' }}>Games Won: {stats.gamesWon}</span>
          <br />
          <span style={{ color: 'red' }}>Games Lost: {stats.gamesLost}</span>
          <br />
          <br />
          <span style={{ color: 'green' }}>
            Won Bids:{' '}
            {(+Moralis.Units.FromWei(`${stats.gamesWonEarnings}`)).toFixed(3)}{' '}
            AVAX
          </span>
          <br />
          <span style={{ color: 'red' }}>
            Lost Bids:{' '}
            {(+Moralis.Units.FromWei(`${stats.gamesLostEarnings}`)).toFixed(3)}{' '}
            AVAX
          </span>
          <br />
          <br />
          <span style={{ color: stats.profit < 0 ? 'red' : 'green' }}>
            Total Profit:{' '}
            {(+Moralis.Units.FromWei(`${stats.profit}`)).toFixed(3)} AVAX
          </span>
        </Box>
      )}
      <TableContainer
        component={Paper}
        sx={{ maxWidth: 850, margin: '50px auto' }}
      >
        <div style={{ padding: '16px 16px 0 16px' }}>
          <h1>My Transactions</h1>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Date</b>
              </TableCell>
              <TableCell>
                <b>Action</b>
              </TableCell>
              <TableCell>
                <b>in / out</b>
              </TableCell>
              <TableCell>
                <b>Amount</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(table || []).map((stat, i) => (
              <TableRow key={i}>
                <TableCell>
                  {stat.ts.toLocaleDateString()} {stat.ts.toLocaleTimeString()}
                </TableCell>
                <TableCell>
                  <span
                    style={{ color: stat.inout === 'in' ? 'green' : 'red' }}
                  >
                    {stat.action}
                  </span>
                </TableCell>
                <TableCell>
                  <span
                    style={{ color: stat.inout === 'in' ? 'green' : 'red' }}
                  >
                    {stat.inout}
                  </span>
                </TableCell>
                <TableCell>
                  <span
                    style={{ color: stat.inout === 'in' ? 'green' : 'red' }}
                  >
                    {(+Moralis.Units.FromWei(`${stat.amount}`)).toFixed(3)} AVAX
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Transactions;
