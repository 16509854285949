import { useState, useEffect } from 'react';
import { ref, onValue, query, equalTo, orderByChild } from 'firebase/database';
import GameCard from '../../modules/gameCard.js';
import { db } from '../../hooks/useFirebase.js';

export default function Slots({ user }) {
  const [allPlayers, setAllPlayers] = useState({});
  const [allGames, setAllGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [slot0, setSlot0] = useState([]);
  const [slot1, setSlot1] = useState([]);
  const [slot2, setSlot2] = useState([]);
  const [slot3, setSlot3] = useState([]);
  const [slot4, setSlot4] = useState([]);
  const [slot5, setSlot5] = useState([]);
  const [slot6, setSlot6] = useState([]);
  const [slot7, setSlot7] = useState([]);
  const [slot8, setSlot8] = useState([]);
  const [slot9, setSlot9] = useState([]);
  const [myGames, setMyGames] = useState([]);

  useEffect(() => {
    const playersPath = ref(db, 'players/');
    onValue(playersPath, (data) => {
      let players = data.val();
      setAllPlayers(players);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    const gamesPath = ref(db, 'games/');
    onValue(query(gamesPath, orderByChild('live'), equalTo(true)), (data) => {
      let all_games = [];
      let my_games = [];
      let slot_0 = [];
      let slot_1 = [];
      let slot_2 = [];
      let slot_3 = [];
      let slot_4 = [];
      let slot_5 = [];
      let slot_6 = [];
      let slot_7 = [];
      let slot_8 = [];
      let slot_9 = [];
      data.forEach((game_object) => {
        let game = game_object.val();
        if (game.live) all_games.push(game);
        if (game.live && game.host === user) my_games.push(game);
        if (game.host !== user) {
          if (game.live && game.slot === 0) slot_0.push(game);
          if (game.live && game.slot === 1) slot_1.push(game);
          if (game.live && game.slot === 2) slot_2.push(game);
          if (game.live && game.slot === 3) slot_3.push(game);
          if (game.live && game.slot === 4) slot_4.push(game);
          if (game.live && game.slot === 5) slot_5.push(game);
          if (game.live && game.slot === 6) slot_6.push(game);
          if (game.live && game.slot === 7) slot_7.push(game);
          if (game.live && game.slot === 8) slot_8.push(game);
          if (game.live && game.slot === 9) slot_9.push(game);
        }
      });
      setAllGames(all_games);
      setMyGames(my_games);
      setSlot0(slot_0);
      setSlot1(slot_1);
      setSlot2(slot_2);
      setSlot3(slot_3);
      setSlot4(slot_4);
      setSlot5(slot_5);
      setSlot6(slot_6);
      setSlot7(slot_7);
      setSlot8(slot_8);
      setSlot9(slot_9);
      setLoading(false);
    });
  }, [user]);

  return (
    <>
      {myGames.length > 0 && (
        <>
          <div style={styles.openGames}>My Games: {myGames.length}</div>
          <div style={styles.container}>
            {!loading
              ? myGames.map((myGame) => (
                  <div style={styles.emptyContainer} key={myGame.gid}>
                    <GameCard game={myGame} players={allPlayers} />
                  </div>
                ))
              : null}
            {!loading &&
              myGames.length % 3 !== 0 &&
              Array(3 - (myGames.length % 3))
                .fill(0)
                .map((_, i) => (
                  <div
                    key={`${i}-${Math.random()}`}
                    style={styles.emptyContainer}
                  />
                ))}
          </div>
        </>
      )}
      <div style={styles.openGames}>
        Open Games: {Math.max(0, allGames.length - myGames.length)}
      </div>
      <div style={styles.container}>
        <div style={styles.emptyContainer}>
          {!loading && slot0.length > 0 ? (
            <GameCard game={slot0[0]} players={allPlayers} />
          ) : null}
        </div>
        <div style={styles.emptyContainer}>
          {!loading && slot1.length > 0 ? (
            <GameCard game={slot1[0]} players={allPlayers} />
          ) : null}
        </div>
        <div style={styles.emptyContainer}>
          {!loading && slot2.length > 0 ? (
            <GameCard game={slot2[0]} players={allPlayers} />
          ) : null}
        </div>
        <div style={styles.emptyContainer}>
          {!loading && slot3.length > 0 ? (
            <GameCard game={slot3[0]} players={allPlayers} />
          ) : null}
        </div>
        <div style={styles.emptyContainer}>
          {!loading && slot4.length > 0 ? (
            <GameCard game={slot4[0]} players={allPlayers} />
          ) : null}
        </div>
        <div style={styles.emptyContainer}>
          {!loading && slot5.length > 0 ? (
            <GameCard game={slot5[0]} players={allPlayers} />
          ) : null}
        </div>
        <div style={styles.emptyContainer}>
          {!loading && slot6.length > 0 ? (
            <GameCard game={slot6[0]} players={allPlayers} />
          ) : null}
        </div>
        <div style={styles.emptyContainer}>
          {!loading && slot7.length > 0 ? (
            <GameCard game={slot7[0]} players={allPlayers} />
          ) : null}
        </div>
        <div style={styles.emptyContainer}>
          {!loading && slot8.length > 0 ? (
            <GameCard game={slot8[0]} players={allPlayers} />
          ) : null}
        </div>
        <div style={styles.emptyContainer}>
          {!loading && slot9.length > 0 ? (
            <GameCard game={slot9[0]} players={allPlayers} />
          ) : null}
        </div>
      </div>
    </>
  );
}

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    padding: '20px 80px',
    justifyContent: 'space-around',
  },
  emptyContainer: {
    width: 425,
    height: 190,
    borderRadius: 5,
    backgroundColor: '#091233',
    marginBottom: 20,
  },
  roomsContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    fontWeight: 'bold',
    paddingLeft: 15,
    backgroundColor: 'black',
  },
  rooms: {
    marginTop: 10,
    backgroundColor: '#0f1942',
    marginLeft: 30,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 5,
    borderRadius: '5px 5px 0px 0px',
    cursor: 'pointer',
  },
  openGames: {
    backgroundColor: '#091129',
    color: 'white',
    fontWeight: 'bold',
    padding: '16px 72px',
  },
  border: {
    borderBottom: 1,
    borderColor: '#0d69f3',
    paddingLeft: 64,
  },
};
