import './App.css';
import { useMoralis } from 'react-moralis';
import { useEffect, useState } from 'react';
import TopMenu from './components/TopMenu';
import Leaderboards from './components/Leaderboards';
import CreateGame from './components/createGame.js';
import Slots from './components/Games';
import logo from './logo.svg';
import Footer from './modules/footer.js';
import Fairness from './modules/fairness.js';
import About from './modules/about.js';
import AdminPanel from './modules/admin.js';
import Transactions from './components/Transactions';

const { REACT_APP_CHAIN_ID, REACT_APP_CHAIN_ID_HEX } = process.env;

function App() {
  const { authenticate, isAuthenticated, Moralis, user, logout } = useMoralis();
  const [correctChainId, setCorrectChainId] = useState(false);
  const [adminIds] = useState(['aIwsKVUtMKoT9WcxmBxTOWgd']);
  const [page, setPage] = useState('home');

  const changeNetwork = async () => {
    await Moralis.switchNetwork(REACT_APP_CHAIN_ID_HEX);
  };

  useEffect(() => {
    async function enable() {
      const web3 = await Moralis.enableWeb3();
      setCorrectChainId(web3 && web3._network.chainId === +REACT_APP_CHAIN_ID);
    }
    enable();
  }, [Moralis]);

  useEffect(() => {
    Moralis.onAccountChanged(async (accounts) => {
      if (isAuthenticated) {
        logout();
      }
    });

    Moralis.onChainChanged(async (chainId) => {
      setCorrectChainId(
        chainId === +REACT_APP_CHAIN_ID || chainId === REACT_APP_CHAIN_ID_HEX
      );
    });
  }, [Moralis, logout, authenticate, isAuthenticated]);

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  if (params.transactions === 'show' && params.walletAddress) {
    return <Transactions walletAddress={params.walletAddress.toLowerCase()} />;
  }

  if (!isAuthenticated || !correctChainId) {
    return (
      <div style={styles.container}>
        <div style={styles.authContainer}>
          <img
            src={logo}
            width="150"
            height="150"
            style={styles.logo}
            alt="logo"
          />
          <p style={styles.logoText}>ATLANTIS GATE</p>
          {correctChainId ? (
            <button style={styles.button} onClick={() => authenticate()}>
              Authenticate
            </button>
          ) : (
            <>
              <b style={{ color: 'red', textAlign: 'center', marginBottom: 8 }}>
                Please connect to <br /> Avalanche Mainnet!
              </b>
              <button style={styles.button} onClick={() => changeNetwork()}>
                Connect to Mainnet
              </button>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div>
      {adminIds.includes(user.id) && <AdminPanel />}
      <TopMenu setPage={setPage} />
      {page === 'home' ? (
        <>
          <CreateGame />
          <Slots user={user.id} />
        </>
      ) : page === 'fair' ? (
        <Fairness />
      ) : page === 'about' ? (
        <About />
      ) : page === 'leaderboards' ? (
        <Leaderboards />
      ) : page === 'transactions' ? (
        <Transactions walletAddress={user.get('ethAddress')} />
      ) : null}
      <Footer setPage={setPage} />
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  authContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#000000bd',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 30,
    borderRadius: 10,
    width: 300,
  },
  logo: {
    marginBottom: 10,
  },
  button: {
    backgroundImage:
      'linear-gradient(85.65deg, rgb(40, 87, 255), rgb(28, 227, 245))',
    border: 'none',
    color: 'white',
    padding: '13px 20px 13px 20px',
    boxShadow: '0 8px 16px rgba(9,18,51,.24)',
    borderRadius: 5,
    cursor: 'pointer',
    fontSize: 20,
    fontWeight: 'bold',
  },
  logoText: {
    fontSize: '20px',
    fontWeight: 'bolder',
    marginBottom: 20,
  },
};

export default App;
