import cx from 'classnames';
import styles from './styles.module.css';

type Props = {
  flip: 'heads' | 'tails';
};

const Coin = ({ flip }: Props) => {
  return (
    <div className={cx(styles.coin, styles[`flip-${flip}`])}>
      <div className={styles['side-heads']} />
      <div className={styles['side-tails']} />
    </div>
  );
};

export default Coin;
