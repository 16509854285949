const styles = {
  createGameContainer: {
    display: 'flex',
    flex: 0.4,
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: '#091233',
    paddingRight: 72,
    paddingLeft: 72,
    alignItems: 'center',
  },
  coinChooseContainer: {
    display: 'flex',
    flex: 0.7,
    alignItems: 'center',
    paddingTop: '5px',
    paddingBottom: '5px',
    backgroundImage: `url(https://tmir.mypinata.cloud/ipfs/QmQSKx32KyZSNULGg1BjnTet4t8iUHujw9qhWKd69BWDRu/gameBg.svg)`,
    backgroundRepeat: 'no-repeat',
  },
  gameCoinImage: {
    width: 64,
    height: 64,
    objectFit: 'cover',
    objectPosition: '-20% 0',
  },
  coinFlipText: {
    fontSize: 25,
    color: 'white',
  },
  chooseSideText: {
    paddingLeft: '15px',
    color: 'white',
  },
  coinPickContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#101838',
    border: '1px solid #0d69f3',
    borderRadius: 8,
    marginLeft: '15px',
    padding: '6px 0px 7px 10px',
  },
  coinPickerHeads: {
    paddingRight: '10px',
    opacity: 1,
    cursor: 'pointer',
  },

  coinPickerTails: {
    paddingRight: '10px',
    opacity: 0.3,
    cursor: 'pointer',
  },

  hostChoiceText: {
    color: 'white',
  },
  //Bet
  betContainer: {
    marginLeft: '20px',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    color: 'white',
  },
  betInput: {
    backgroundColor: '#101838',
    color: 'white',
    fontSize: '20px',
    padding: '10px 10px',
    marginLeft: '10px',
    border: '1px solid #0d69f3',
    borderRadius: 5,
    height: '48px',
    width: '107px',
    marginRight: '10px',
  },
  bidAmountsContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: '#090f26',
    padding: '3px',
    borderRadius: '5px',
  },
  bidAmountButtons: {
    display: 'flex',
    maxWidth: '50px',
    flex: 1,
    padding: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#101838',
    margin: '5px',
    fonstSize: '16px',
    cursor: 'pointer',
    borderRadius: '5px',
  },
  createGameButton: {
    height: '33px',
    width: '140px',
    color: 'white',
    fontSize: '14px',
    margin: '12px 0px',
    padding: '4px 16px',
    borderRadius: '5px',
    backgroundColor: '#198754',
  },
  errMessageContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    backgroundColor: '#8f0202',
    padding: '10px 0px',
  },
  errMessage: {
    color: 'white',
  },
  coinChoice: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-end',
  },
};

export default styles;
