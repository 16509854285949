//Background Animation
export const backgroundEFX = (game, user) => {
  let color;
  if (user.id === game.host && game.hostChoice === game.result) color = 'green';
  if (user.id === game.host && game.hostChoice !== game.result) color = 'red';
  if (user.id === game.opponent && game.hostChoice !== game.result)
    color = 'green';
  if (user.id === game.opponent && game.hostChoice === game.result)
    color = 'red';
  document.getElementById(`${game.gid}-container`).animate(
    [
      // keyframes
      {
        backgroundSize: '100%',
        backgroundColor: '#091233',
      },
      {
        backgroundSize: '200%',
        backgroundColor: '#091233',
      },
      {
        backgroundSize: '300%',
        backgroundColor: '#ba9b00',
      },
      {
        backgroundSize: '100%',
        backgroundColor: color,
      },
    ],
    {
      // timing options
      duration: 3000,
      iterations: 1,
      transition: 'easeInOut',
      fill: 'forwards',
    }
  );
};
