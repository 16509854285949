import { storage } from '../hooks/useFirebase.js';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useMoralis, useMoralisCloudFunction } from 'react-moralis';
import FileUploadIcon from '@mui/icons-material/FileUpload';

function SaveFile() {
  const { user } = useMoralis();

  const { fetch: setPlayerInfo } = useMoralisCloudFunction('setPlayerInfo');

  const uploadFile = async (fileTarget) => {
    const imageRef = ref(storage, user.id);
    await uploadBytes(imageRef, fileTarget).then(() => {
      getDownloadURL(imageRef).then((url) => {
        setPlayerInfo({
          onSuccess: () => {
            console.log('Successfully updated user profile image');
          },
          params: {
            avatar: url,
          },
        });
      });
    });
  };

  const fileInput = (e) => {
    uploadFile(e.target.files[0]);
  };

  return (
    <div style={styles.imageButtonContainer}>
      <input type="file" id="upload" onChange={fileInput} hidden />
      <label htmlFor="upload" style={styles.label}>
        <FileUploadIcon />
        Upload
      </label>
    </div>
  );
}

const styles = {
  imageButtonContainer: {},

  label: {
    backgroundColor: '#0d69f3',
    color: 'white',
    borderRadius: '5px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '3px',
    paddingLeft: '12px',
    paddingRight: '15px',
  },
};

export default SaveFile;
