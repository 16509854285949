import { equalTo, onValue, orderByChild, query, ref } from 'firebase/database';
import { db } from '../hooks/useFirebase.js';
import { useEffect, useState } from 'react';
import { useMoralis } from 'react-moralis';
import CircularProgress from '@mui/material/CircularProgress';

export default function GameStats() {
  const [playerGames, setPlayerGames] = useState({});
  const { user } = useMoralis();
  const [loading, isLoading] = useState(true);

  //Get Game Stats
  useEffect(() => {
    isLoading(true);
    const gamesPath = ref(db, 'games/');
    onValue(query(gamesPath, orderByChild('live'), equalTo(false)), (games) => {
      let set_games = {
        allGames: [],
        hostedGames: [],
        joinedGames: [],
        wins: 0,
        loss: 0,
        totalGains: 0,
        totalBids: 0,
      };
      games.forEach((data) => {
        let game = data.val();
        if (!game.live) {
          if (game.host === user.id || game.opp === user.id)
            set_games.allGames.push(game);
          if (game.host === user.id) set_games.hostedGames.push(game);
          if (game.opp === user.id) set_games.joinedGames.push(game);
          if (
            (game.host === user.id && game.hostChoice === game.result) ||
            (game.opp === user.id && game.hostChoice !== game.result)
          )
            set_games.wins++;
          if (
            (game.host === user.id && game.hostChoice !== game.result) ||
            (game.opp === user.id && game.hostChoice === game.result)
          )
            set_games.loss++;
          if (
            (game.host === user.id && game.hostChoice === game.result) ||
            (game.opp === user.id && game.hostChoice !== game.result)
          )
            set_games.totalGains = game.hostBid + set_games.totalGains;
          if (game.host === user.id || game.opp === user.id)
            set_games.totalBids = game.hostBid + set_games.totalBids;
        }
      });
      setPlayerGames(set_games);
      isLoading(false);
    });
  }, [user.id]);

  const totalGains = +playerGames.totalGains * 0.97;

  return (
    <div style={styles.container}>
      {!loading ? (
        <>
          <p style={styles.statsText}>
            Total Games: {playerGames.allGames.length}{' '}
          </p>
          <p style={styles.statsText}>
            Hosted Games: {playerGames.hostedGames.length}{' '}
          </p>
          <p style={styles.statsText}>
            Joined Games: {playerGames.joinedGames.length}{' '}
          </p>
          <p style={styles.statsText}>
            Wins: <span style={{ color: 'green' }}>{playerGames.wins}</span>{' '}
          </p>
          <p style={styles.statsText}>
            Loss: <span style={{ color: 'red' }}>{playerGames.loss}</span>{' '}
          </p>
          <p style={styles.statsText}>
            Total Gains:{' '}
            <span style={{ color: 'yellow' }}>{totalGains.toFixed(2)}</span>{' '}
          </p>
          <p style={styles.statsText}>
            Total Bids:{' '}
            <span style={{ color: 'orange' }}>
              {playerGames.totalBids.toFixed(2)}
            </span>{' '}
          </p>
        </>
      ) : (
        <div style={styles.container2}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    width: '100%',
    backgroundColor: 'black',
    padding: 20,
    marginBottom: 15,
  },
  container2: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
};
