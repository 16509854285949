import { useEffect, useState } from 'react';
import { useMoralisQuery } from 'react-moralis';

const deductFees = (amount) => 2 * amount * 0.97 - amount;

const useTransactions = (walletAddress) => {
  const { fetch: fetchMoveLost } = useMoralisQuery(
    'EventsWalletMoveBalance',
    (query) => query.equalTo('from', walletAddress).limit(10000),
    [],
    { autoFetch: false }
  );

  const { fetch: fetchMoveWon } = useMoralisQuery(
    'EventsWalletMoveBalance',
    (query) => query.equalTo('to', walletAddress).limit(10000),
    [],
    { autoFetch: false }
  );

  const { fetch: fetchDeposit } = useMoralisQuery(
    'EventsWalletDeposit',
    (query) => query.equalTo('wallet', walletAddress).limit(10000),
    [],
    { autoFetch: false }
  );

  const { fetch: fetchWithdraw } = useMoralisQuery(
    'EventsWalletWithdraw',
    (query) => query.equalTo('wallet', walletAddress).limit(10000),
    [],
    { autoFetch: false }
  );

  const [table, setTable] = useState([]);
  const [stats, setStats] = useState({});

  useEffect(() => {
    fetchMoveWon({
      onSuccess: (data0) => {
        fetchMoveLost({
          onSuccess: (data1) => {
            fetchDeposit({
              onSuccess: (data2) => {
                fetchWithdraw({
                  onSuccess: (data3) => {
                    const wonFlips = data0.map((item) => {
                      return {
                        ts: item.get('block_timestamp'),
                        amount: deductFees(+item.get('amount')),
                        action: 'Flip Won',
                        inout: 'in',
                      };
                    });

                    const lostFlips = data1.map((item) => {
                      return {
                        ts: item.get('block_timestamp'),
                        amount: +item.get('amount'),
                        action: 'Flip Lost',
                        inout: 'out',
                      };
                    });

                    const deposits = data2.map((item) => {
                      return {
                        ts: item.get('block_timestamp'),
                        amount: +item.get('amount'),
                        action: 'Deposit',
                        inout: 'in',
                      };
                    });

                    const withdraws = data3.map((item) => {
                      return {
                        ts: item.get('block_timestamp'),
                        amount: +item.get('amount'),
                        action: 'Withdraw',
                        inout: 'out',
                      };
                    });

                    const tableData = [
                      ...wonFlips,
                      ...lostFlips,
                      ...deposits,
                      ...withdraws,
                    ];

                    setTable(
                      tableData.sort((a, b) => b.ts.getTime() - a.ts.getTime())
                    );

                    setStats({
                      games: wonFlips.length + lostFlips.length,
                      gamesWon: wonFlips.length,
                      gamesLost: lostFlips.length,
                      gamesWonEarnings: wonFlips.reduce(
                        (acc, curr) => acc + curr.amount,
                        0
                      ),
                      gamesLostEarnings: lostFlips.reduce(
                        (acc, curr) => acc + curr.amount,
                        0
                      ),
                      profit:
                        wonFlips.reduce((acc, curr) => acc + curr.amount, 0) -
                        lostFlips.reduce((acc, curr) => acc + curr.amount, 0),
                    });
                  },
                });
              },
            });
          },
        });
      },
    });
  }, [fetchDeposit, fetchMoveLost, fetchMoveWon, fetchWithdraw]);

  return { table, stats };
};

export default useTransactions;
