import { useEffect, useState } from 'react';
import { Alert, Button, Snackbar } from '@mui/material';
import { useMoralis, useMoralisCloudFunction } from 'react-moralis';
import { ref, onValue } from 'firebase/database';
import { HEADS, TAILS } from '../config/constants.js';
import { db } from '../hooks/useFirebase.js';

import headsImg from '../img/heads-mini.svg';
import tailsImg from '../img/tails-mini.svg';
import styles from './createGame.styles.js';

export default function CreateGame() {
  const [error, setError] = useState(null);
  const [creatingGame, setCreatingGame] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [hostChoice, setHostChoice] = useState(HEADS);
  const [hostBid, setHostBid] = useState(0);
  const [player, setPlayer] = useState({});

  const { user, isAuthenticated, Moralis } = useMoralis();

  const params = {
    hostChoice,
    hostBid: +Moralis.Units.ETH(`${hostBid || 0}`),
  };
  const { fetch: hostGame } = useMoralisCloudFunction('hostGame', params, {
    autoFetch: false,
  });

  const playerBalance = (+Moralis.Units.FromWei(
    `${parseInt(player?.balance ?? 0)}`
  )).toFixed(3);

  useEffect(() => {
    if (user) {
      const player = ref(db, 'players/' + user.id);
      onValue(player, (data) => {
        let _player = data.val();
        setPlayer(_player);
      });
    }
  }, [user]);

  const createGame = () => {
    if (hostBid === 0) {
      setError('Place bid amount');
      setAlertOpen(true);
      return;
    }

    if (hostBid < 0.05) {
      setError('Bid amount must be at least 0.05');
      setAlertOpen(true);
      return;
    }

    if (hostBid > 1000) {
      setError('Bid amount must be less than 1000');
      setAlertOpen(true);
      return;
    }

    if (+playerBalance < hostBid) {
      setError('You do not have enough tokens');
      setAlertOpen(true);
      return;
    }

    setCreatingGame(true);

    return hostGame({
      onSuccess: (data) => {
        setCreatingGame(false);
      },
      onError: (error) => {
        setCreatingGame(false);
      },
    });
  };

  function handleHostChoice(choice) {
    if (!choice) return alert('Please pick a coin');
    if (choice === 'heads') {
      document.getElementById('pick-heads').style.opacity = 1;
      document.getElementById('pick-tails').style.opacity = 0.3;
      setHostChoice(HEADS);
    }
    if (choice === 'tails') {
      document.getElementById('pick-tails').style.opacity = 1;
      document.getElementById('pick-heads').style.opacity = 0.3;
      setHostChoice(TAILS);
    }
  }

  function handleBidAmountInput(i) {
    if (i === 'clear') return setHostBid(0);
    if (i === '0.05' && hostBid < 1000) return setHostBid(hostBid + 0.05);
    if (i === '1' && hostBid < 1000) return setHostBid(hostBid + 1);
    if (i === '10' && hostBid < 1000) return setHostBid(hostBid + 10);
    if (i === '100' && hostBid < 1000) return setHostBid(hostBid + 100);
    if (i === '1/2' && hostBid > 1) return setHostBid(hostBid / 2);
    if (i === 'x2' && hostBid * 2 < 1000) return setHostBid(hostBid * 2);
    if (i === 'max') return setHostBid(Math.floor(+playerBalance * 100) / 100);
  }

  function handleClose() {
    setAlertOpen(false);
  }

  return (
    <>
      <div style={styles.createGameContainer}>
        <div style={styles.coinChooseContainer}>
          {/* <img style={styles.gameCoinImage} src={gameCoinImg} alt="Coin"/> */}
          <h1 style={styles.coinFlipText}>Coin Flip</h1>
        </div>
        <div style={styles.betContainer}>
          <div style={styles.coinChoice}>
            <p style={styles.chooseSideText}>Choose Side</p>
            <div style={styles.coinPickContainer}>
              <img
                id="pick-heads"
                onClick={() => handleHostChoice('heads')}
                style={styles.coinPickerHeads}
                src={headsImg}
                alt={'heads'}
              />
              <img
                id="pick-tails"
                onClick={() => handleHostChoice('tails')}
                style={styles.coinPickerTails}
                src={tailsImg}
                alt={'tails'}
              />
            </div>
            {/* <FontAwesomeIcon icon={faCoins} /> */}
            <input
              id="input"
              onChange={null}
              placeholder="Bet Value"
              value={hostBid.toFixed(2)}
              disabled
              style={styles.betInput}
              type="number"
              name="Bet Amount"
              min="1"
              max="1000"
            />
          </div>
          <div style={styles.bidAmountsContainer}>
            <div
              onClick={() => handleBidAmountInput('clear')}
              style={styles.bidAmountButtons}
            >
              Clear
            </div>
            <div
              onClick={() => handleBidAmountInput('0.05')}
              style={styles.bidAmountButtons}
            >
              +0.05
            </div>
            <div
              onClick={() => handleBidAmountInput('1')}
              style={styles.bidAmountButtons}
            >
              +1
            </div>
            <div
              onClick={() => handleBidAmountInput('10')}
              style={styles.bidAmountButtons}
            >
              +10
            </div>
            <div
              onClick={() => handleBidAmountInput('100')}
              style={styles.bidAmountButtons}
            >
              +100
            </div>
            <div
              onClick={() => handleBidAmountInput('1/2')}
              style={styles.bidAmountButtons}
            >
              1/2
            </div>
            <div
              onClick={() => handleBidAmountInput('x2')}
              style={styles.bidAmountButtons}
            >
              X2
            </div>
            <div
              onClick={() => handleBidAmountInput('max')}
              style={styles.bidAmountButtons}
            >
              Max
            </div>
          </div>
        </div>
        {isAuthenticated ? (
          <Button
            onClick={createGame}
            disabled={creatingGame}
            style={styles.createGameButton}
          >
            {creatingGame ? (
              <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              'Create Game'
            )}
          </Button>
        ) : null}
      </div>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
}
