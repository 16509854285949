import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
} from '@mui/material';
import { useMoralis } from 'react-moralis';
import { getWalletFromId } from '../../utils/addressId';
import useStats from './useStats';
import styles from './styles.module.css';

const Leaderboards = ({ flip }: Props) => {
  const { table, moreData } = useStats();
  const { Moralis } = useMoralis();

  const revealAddress = (userId) => {
    // const playerQuery = new Moralis.Query('Player');
    // getWalletFromId(playerQuery, userId).then((wallet) => {
    //   alert('Wallet: ' + wallet);
    // });
  };

  return (
    <>
      {moreData && (
        <div className={styles.topAddresses}>
          <span>
            Total Games:{' '}
            <b>
              {moreData.games || 'TBD'} {'games'}
            </b>
          </span>
          <span>
            Highest single game win:{' '}
            <b>{moreData.highestBidWinnerAddress || 'TBD'}</b>
          </span>
          <span>
            Highest winnings overall:{' '}
            <b>{moreData.gamesWinningsAddress || 'TBD'}</b>
          </span>
          <span>
            Highest number of games played:{' '}
            <b>{moreData.gamesPlayedAddress || 'TBD'}</b>
          </span>
          <span>
            Highest number of games won:{' '}
            <b>{moreData.gamesWonAddress || 'TBD'}</b>
          </span>
          <span>
            Highest number of games lost:{' '}
            <b>{moreData.gamesLostAddress || 'TBD'}</b>
          </span>
          <span>
            Most losses in a row: <b>{moreData.loserInRowAddress || 'TBD'}</b>
          </span>
          <span>
            Most wins in a row: <b>{moreData.winnerInRowAddress || 'TBD'}</b>
          </span>
          <span>
            Highest total win over 3 games in a row:{' '}
            <b>{moreData.specialInARowWinningsOver3Games || 'TBD'}</b>
          </span>
        </div>
      )}
      <TableContainer
        component={Paper}
        sx={{ maxWidth: 850, margin: '50px auto' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Rank</b>
              </TableCell>
              <TableCell>
                <b>User ID</b>
              </TableCell>
              <TableCell>
                <b>Coins Flipped</b>
              </TableCell>
              <TableCell>
                <b>Games Won</b>
              </TableCell>
              <TableCell>
                <b>Winnings</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(table || []).slice(0, 10).map((stat, i) => (
              <TableRow key={i}>
                <TableCell>#{i + 1}</TableCell>
                <TableCell>
                  <span
                    onClick={() => revealAddress(stat.userId)}
                    className={styles.tableCellPointer}
                  >
                    {stat.address}
                  </span>
                </TableCell>
                <TableCell>{stat.gamesPlayed}</TableCell>
                <TableCell>{stat.gamesWon}</TableCell>
                <TableCell>{(+stat.gamesWinnings).toFixed(3)} AVAX</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Leaderboards;
