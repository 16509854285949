import * as React from 'react';
import { db } from '../hooks/useFirebase.js';
import { ref, onValue, update } from 'firebase/database';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useMoralis } from 'react-moralis';
import GetBalance from '../modules/getBalance.js';
import SaveFile from '../modules/saveFile.js';
import GameStats from '../modules/gameStats.js';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ProfileModal from '../components/ProfileModal/index.js';

function ChildModal() {
  const [open, setOpen] = React.useState(false);

  //Handle open/close
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button onClick={handleOpen} variant="outlined">
        Stats
      </Button>
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...styles.modal, width: 350 }}>
          <h3 style={{ marginBottom: 10 }}>Game Stats</h3>
          <GameStats />
          <Button onClick={handleClose} variant="outlined">
            Close
          </Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default function NestedModal({ setPage }) {
  const [currentPlayer, setCurrentPlayer] = React.useState();
  const [saveImageButton, setSaveImageButton] = React.useState(false);
  const [loading, isLoading] = React.useState(true);
  const { logout, user } = useMoralis();

  const [open, setOpen] = React.useState(false);

  // Get players
  React.useEffect(() => {
    isLoading(true);
    const playerPath = ref(db, `players/${user.id}`);
    onValue(playerPath, (data) => {
      let player = data.val();
      setCurrentPlayer(player);
      isLoading(false);
    });
  }, [user.id]);

  // Handle open/close
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSaveImageButton(false);
  };

  function openImageSelect() {
    if (saveImageButton) return setSaveImageButton(false);
    if (!saveImageButton) return setSaveImageButton(true);
  }

  const address = user?.get('ethAddress') ?? '';

  return (
    <div>
      <div onClick={handleOpen} style={styles.walletConnectButton}>
        <AccountBalanceWalletIcon />
        <p style={styles.walletConnectText}>
          {address.slice(0, 5) + '...' + address.slice(-5)}
        </p>
      </div>
      {currentPlayer && (
        <ProfileModal
          open={open}
          user={user}
          handleClose={handleClose}
          saveImageButton={saveImageButton}
          logout={logout}
          loading={loading}
          profileImage={currentPlayer.avatar}
          openImageSelect={openImageSelect}
          walletAddress={address}
          setPage={setPage}
        />
      )}
    </div>
  );
}

const styles = {
  walletConnectButton: {
    border: '2px solid #0d69f3',
    color: 'white',
    padding: '13px 20px 13px 20px',
    boxShadow: '0 8px 16px rgba(9,18,51,.24)',
    borderRadius: 5,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  walletConnectText: {
    paddingLeft: '5px',
  },
  walletConnectTop: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    color: 'white',
    bgcolor: 'white',
    border: '2px solid #283c86',
    borderRadius: '5px',
    backgroundColor: '#101838',
    boxShadow: 24,
    p: 4,
  },
  username: {
    color: 'white',
    backgroundColor: '#070c1d',
    alignItems: 'center',
    border: 'none',
    fontSize: 30,
    width: '100%',
    padding: 10,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  saveNameButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'green',
    height: 40,
    width: 40,
    fontSize: 16,
    padding: 20,
    marginLeft: 20,
    cursor: 'pointer',
  },
  profileImageContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 25,
    marginBottom: 25,
  },
  profileImage: {
    width: 150,
    height: 150,
    backgroundColor: 'grey',
    borderRadius: 100,
    border: '3px solid #283c86',
    cursor: 'pointer',
  },
  imageButtonContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    marginTop: 15,
  },
  files: {
    backgroundColor: 'black',
    padding: 11,
  },
  exitButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 10,
    fontWeight: 'bold',
    cursor: 'pointer',
  },
};
