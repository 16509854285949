import fairbg from '../img/fair-bg.png';

export default function Fairness() {
  return (
    <div style={styles.container}>
      <div style={styles.left}>
        <h2 style={styles.fairh2}>How can I know that the game is fair?</h2>
        <br></br>
        <p style={{ lineHeight: '1.75rem', fontSize: '18px' }}>
          When a host creates a game, they are given the initial option between
          heads or tails. This is called the 'Host Choice' and it is determined
          before an opposing player joins the game. Once an opposing player
          'opp' joins a game, they are assigned the opposite choice and the game
          server will automaticly flip the coin result with a 50/50 random
          chance. The game "Result" is determined by 0 (heads) or 1 (tails). The
          results are logged then the reward is outputted to the game winner.
        </p>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    padding: '50px 150px',
    backgroundImage: `url(${fairbg})`,
    height: '800px',
  },
  left: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '25px',
    color: 'white',
    fontSize: '16px',
  },
  right: {
    display: 'flex',
    flex: 1,
    padding: '25px',
    color: 'white',
    fontSize: '16px',
  },
  fairh2: {
    fontSize: '40px',
  },
};
