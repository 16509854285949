import { useState, useEffect } from 'react';
import { useMoralis, useMoralisCloudFunction } from 'react-moralis';
import { ref, onValue } from 'firebase/database';

import DepositWithdraw from '../../modules/depositWithdraw.js';
import ConnectButton from '../../modules/connectButton';
import { db } from '../../hooks/useFirebase.js';

import logo from '../../logo.svg';
import avaxLogo from '../../img/avax.svg';
import styles from './styles.module.css';

export default function TopMenu({ setPage }) {
  const { user, Moralis } = useMoralis();
  const [player, setPlayer] = useState({});
  const [loading, isLoading] = useState(true);

  const playerBalance = (+Moralis.Units.FromWei(
    `${parseInt(player?.balance ?? 0)}`
  )).toFixed(3);

  const { fetch: syncPlayer } = useMoralisCloudFunction(
    'syncPlayer',
    {},
    { autoFetch: false }
  );

  useEffect(() => {
    isLoading(true);
    if (user && syncPlayer) {
      const current_player = ref(db, 'players/' + user.id);
      onValue(current_player, (data) => {
        let _player = data.val();
        setPlayer(_player);
        isLoading(false);
      });
      syncPlayer({
        onSuccess: (data) => {},
      });
    }
  }, [user, syncPlayer]);

  return (
    <div className={styles.navBarContainer}>
      <div className={styles.navBarLogo}>
        <div className={styles.logoCont}>
          <img src={logo} alt="Atlantis Gate" />
          <p className={styles.logoText}>ATLANTIS GATE</p>
        </div>
        <div className={styles.menuContainer}>
          <div onClick={() => setPage('home')} className={styles.menuButton}>
            Home
          </div>
          <div onClick={() => setPage('fair')} className={styles.menuButton}>
            Fairness
          </div>
          <div onClick={() => setPage('about')} className={styles.menuButton}>
            About
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.avaxbalContainer}>
          <DepositWithdraw
            currentBal={playerBalance}
            bal={
              <p className={styles.avaxbalance}>
                {!loading ? playerBalance : 'Loading'}
              </p>
            }
          />
          <img src={avaxLogo} style={{ width: '23px' }} alt="Avax Logo" />
        </div>
        <div className={styles.navBarConnect}>
          <ConnectButton setPage={setPage} />
        </div>
      </div>
    </div>
  );
}
